<template>
  <base-material-card
    id="invite"
    class="py-0"
    :title="$t('invite', { name: $tc('artist') })"
    icon="mdi-account-edit"
    ><v-row justify="center" style="margin-right: 0px" align="center">
      <v-col
        cols="11"
        :md="
          $store.getters['auth/getSetting']('comisions_active') === 1 ? 10 : 6
        "
        class="pr-0"
      >
        <v-card elevation="10" :style="$vuetify.breakpoint.mdAndUp ? '' : ''">
          <v-card-title class="mt-1">
            <h2
              style="
                color: var(--v-primary-base);
                margin: 20px auto;
                font-weight: normal;
                text-transform: uppercase;
                text-decoration: underline;
                margin-bottom: 10px;
              "
            >
              {{ $t("tattooers.email_invitation") }}
            </h2>
          </v-card-title>
          <div class="close">
            <v-btn icon @click="$router.back()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <form
              v-if="tattooer"
              action
              data-vv-scope="tattooer-form"
              @submit.prevent="validateForm('tattooer-form')"
            >
              <v-row v-if="invited" justify="center">
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    dense
                    id="tattooerURL"
                    readonly
                    v-model="invited"
                    :label="$t('tattooers.invite_link')"
                  >
                    <template slot="prepend">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="pa-0"
                            v-on="on"
                            style="margin-top: -8px !important"
                            @click="copy('tattooerURL')"
                            icon
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("copy") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-else justify="center">
                <v-col class="py-0" cols="12" md="5">
                  <label v-text="$t('email')" />

                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="tattooer.user.email"
                    data-vv-validate-on="blur"
                    v-validate="'required|email'"
                    data-vv-name="email"
                    :error-messages="errors.collect('tattooer-form.email')"
                    color="secondary"
                  />
                  <!--:error-messages="errors.collect('tattooer-form.email')"-->
                </v-col>
                <v-col class="py-0" cols="12" md="5">
                  <label v-text="$t('emails.from')" />
                  <v-menu
                    ref="menu"
                    tabindex="13"
                    v-model="menu.from"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        outlined
                        dense
                        v-model="computedDateFormatted"
                        color="secondary"
                        hide-details
                        :error-messages="errors.collect('tattooer-form.from')"
                        v-bind:class="[
                          { 'error--text': tattooer.to < tattooer.from },
                        ]"
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      first-day-of-week="1"
                      v-model="tattooer.from"
                      color="primary"
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      data-vv-name="from"
                      no-title
                      scrollable
                      :show-current="today"
                      :min="today"
                      @input="menu.from = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="center" v-if="!invited">
                <v-col class="py-0" cols="6" md="5">
                  <v-checkbox
                    v-model="tattooer.indefinido"
                    class="mt-7"
                    hide-details
                  >
                    <template v-slot:label
                      ><div style="margin-top: 2px">
                        {{ $t("tattooers.invites.indef") }}
                      </div></template
                    >
                  </v-checkbox>
                </v-col>
                <v-col class="py-0" cols="6" md="5">
                  <label v-text="'Hasta'" />
                  <v-menu
                    ref="menu"
                    tabindex="13"
                    v-model="menu.to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        outlined
                        dense
                        :disabled="tattooer.indefinido"
                        v-model="computedToFormatted"
                        color="secondary"
                        hide-details
                        :error-messages="errors.collect('tattooer-form.to')"
                        v-bind:class="[
                          { 'error--text': tattooer.to < tattooer.from },
                        ]"
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      first-day-of-week="1"
                      v-model="tattooer.to"
                      color="primary"
                      data-vv-name="to"
                      no-title
                      scrollable
                      :show-current="today"
                      :min="today"
                      @input="menu.to = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="!invited">
                <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  md="5"
                  v-if="
                    $store.getters['auth/getSetting'](
                      'calendar_relations_configurated'
                    )
                  "
                >
                  <label class="one-line pl-0 ml-md-0 ml-n3 pl-md-4">
                    {{ $t("calendar_configuration_tattooer_invite") }}
                  </label>
                  <v-autocomplete
                    :items="calendars"
                    outlined
                    dense
                    hide-details
                    single-line
                    clearable
                    v-model="tattooer.calendar_id"
                    item-value="id"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon
                        :color="item.color"
                        class="mr-1"
                        v-if="item.id === -1"
                      >
                        mdi-close-circle
                      </v-icon>
                      <v-icon :color="item.color" class="mr-1" v-else>
                        mdi-circle
                      </v-icon>
                      {{ item.name }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon
                        :color="item.color"
                        class="mr-1"
                        v-if="item.id === -1"
                      >
                        mdi-close-circle
                      </v-icon>
                      <v-icon :color="item.color" class="mr-1" v-else>
                        mdi-circle
                      </v-icon>
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-spacer />
              </v-row>
              <!--
                <v-row v-if="!invited" justify="center">
                <v-col cols="12" md="5"></v-col>
                <v-col cols="12" md="5">
                  <v-select
                    :items="items"
                    outlined
                    dense
                    multiple
                    :label="$t('tattooers.artist_type')"
                    v-model="tattooer.type"
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    item-value="id"
                    :item-text="(x) => $tc(x.name)"
                    :error-messages="
                      errors.collect('tattooer-form.artist-type')
                    "
                    data-vv-name="artist-type"
                  >
                  </v-select>
                </v-col>
              </v-row>
              -->
              <v-card
                v-if="
                  !invited &&
                  $store.getters['auth/getSetting']('comisions_active') === 1
                "
                outlined
                max-width="1000px"
                class="mx-auto"
              >
                <v-card-title
                  ><h2
                    style="
                      color: var(--v-primary-base);
                      margin: 0 auto;
                      text-transform: uppercase;
                      font-weight: normal;
                    "
                  >
                    {{ $t("tattooers.studio_tattooer_commissions") }}
                  </h2></v-card-title
                >
                <v-card-text id="select_commisions">
                  <v-row justify="center">
                    <v-col cols="12">
                      <ComisionForm
                        ref="comisionForm"
                        :type="type"
                        :commissions="tattooer.comisions"
                      ></ComisionForm>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="pa-3 text-right">
                <v-btn
                  outlined
                  @click="$router.back()"
                  style="height: 25px; width: 100px"
                  elevation="0"
                  class="mr-3"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  @click="invited = null"
                  style="
                    width: 165px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
                  elevation="0"
                  v-if="invited"
                >
                  <v-icon>mdi-</v-icon>{{ $t("tattooers.invite_another") }}
                </v-btn>
                <v-btn
                  type="submit"
                  style="
                    width: 100px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
                  elevation="0"
                  v-else
                >
                  <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
                  {{ $t("send", { name: "" }) }}
                </v-btn>
              </div>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";

import * as moment from "moment";

import constants from "@/constants";
export default {
  name: "TattooerInvite",
  components: {
    ComisionForm: () => import("@/components/tattooer/CommissionsForm"),
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    if (this.$route.params.email) {
      this.tattooer.user.email = this.$route.params.email;
    }
    //GET HASH
    this.getHash();
    this.fetchTypes();
    //this.$refs.comisions.changeComisions();
    if (
      this.$store.getters["auth/getSetting"]("calendar_relations_configurated")
    )
      this.fetchCalendars();
  },
  data() {
    return {
      tattooer: {
        indefinido: true,
        user: { role_id: constants.roles.TATTOOER },
        type: null,
        comisions: {
          type: null,
          comisions: [],
          price: null,
        },
      },
      calendars: [],
      items: [],
      today: null,
      menu: { from: false, to: false },
      invited: null,
      hash: null,
      comisionsType: [
        { text: "%", value: "percentage", style: "font-size: 10px" },
        { text: "€", value: "money" },
      ],
    };
  },
  computed: {
    computedDateFormatted() {
      if (!this.tattooer.from) return null;
      const [year, month, day] = this.tattooer.from.split("-");
      return `${day}/${month}/${year}`;
    },

    computedToFormatted() {
      if (!this.tattooer.to) return null;
      const [year, month, day] = this.tattooer.to.split("-");
      return `${day}/${month}/${year}`;
    },

    URL() {
      return `${window.location.origin}/?#/inscription/tattooer/studio/${this.$store.state.auth.user.id}/${this.hash}`;
    },
  },
  methods: {
    ...mapActions("calendar", ["getCalendars"]),
    fetchCalendars() {
      ////console.log("fetch");
      this.getCalendars({
        account: 1,
      }).then((data) => {
        console.log(data);
        this.calendars = [
          { id: -1, name: this.$t("calendar_no_sincro"), color: "red" },
          ...data["Google"],
        ];
      });
    },
    ...mapActions("tattooers", ["inviteTattooer", "getTypeArtist"]),
    ...mapActions("auth", ["getUserHash"]),
    getHash() {
      this.getUserHash().then((data) => {
        ////console.log(data.hash);
        this.hash = data.hash;
      });
    },
    fetchTypes() {
      this.loading = true;
      this.getTypeArtist().then((tattooers) => {
        ////console.log(tattooers);
        this.items = tattooers.data;
        this.loading = false;
      });
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (
          result &&
          (this.tattooer.from < this.tattooer.to || this.tattooer.indefinido)
        ) {
          //INVITAR
          this.inviteTattooer({
            tattooer: this.tattooer,
          }).then((result) => {
            ////console.log(result.success);
            if (result.success) {
              this.invited = result.url;
              this.tattooer = {
                indefinido: true,
                user: { role_id: constants.roles.TATTOOER },
                comisions: {
                  tattooer: { type: "", valor: "" },
                  studio: { type: "", valor: "" },
                },
              };
              this.$alert(this.$t("tattooer_invited_success"), "", "");
              // if (!this.invited) this.invited = "no_link";
            } else {
              if (result.data === "too_many_users")
                this.$confirm(
                  this.$t("tattooer_invited_error." + result.data),
                  "",
                  ""
                ).then((x) => {
                  this.$router.push({
                    name: "UserSettings",
                    params: { type: "myplan" },
                  });
                });
              else
                this.$alert(
                  this.$t("tattooer_invited_error." + result.data),
                  "",
                  ""
                );
            }
          });
        }
      });
    },
    copy(valor) {
      /* Get the text field */
      var copyText = document.getElementById(valor);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$alert(this.$t("link_copy_success"), "", "");
    },
  },
};
</script>

<style lang="sass">
#invite
  .v-input--selection-controls__input
    margin-top: -6px

  .v-select__slot input
    position: absolute
  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important
  #select_commisions

    .big .v-select__selection
      font-size: 17px !important
      min-width: 100%
</style>