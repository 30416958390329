var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{staticClass:"py-0",attrs:{"id":"invite","title":_vm.$t('invite', { name: _vm.$tc('artist') }),"icon":"mdi-account-edit"}},[_c('v-row',{staticStyle:{"margin-right":"0px"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"11","md":_vm.$store.getters['auth/getSetting']('comisions_active') === 1 ? 10 : 6}},[_c('v-card',{style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : ''),attrs:{"elevation":"10"}},[_c('v-card-title',{staticClass:"mt-1"},[_c('h2',{staticStyle:{"color":"var(--v-primary-base)","margin":"20px auto","font-weight":"normal","text-transform":"uppercase","text-decoration":"underline","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.email_invitation"))+" ")])]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.tattooer)?_c('form',{attrs:{"action":"","data-vv-scope":"tattooer-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('tattooer-form')}}},[(_vm.invited)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"tattooerURL","readonly":"","label":_vm.$t('tattooers.invite_link')},model:{value:(_vm.invited),callback:function ($$v) {_vm.invited=$$v},expression:"invited"}},[_c('template',{slot:"prepend"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0",staticStyle:{"margin-top":"-8px !important"},attrs:{"icon":""},on:{"click":function($event){return _vm.copy('tattooerURL')}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,87977154)},[_c('span',[_vm._v(_vm._s(_vm.$t("copy")))])])],1)],2)],1)],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('email'))}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","data-vv-name":"email","error-messages":_vm.errors.collect('tattooer-form.email'),"color":"secondary"},model:{value:(_vm.tattooer.user.email),callback:function ($$v) {_vm.$set(_vm.tattooer.user, "email", $$v)},expression:"tattooer.user.email"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('emails.from'))}}),_c('v-menu',{ref:"menu",attrs:{"tabindex":"13","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({class:[
                        { 'error--text': _vm.tattooer.to < _vm.tattooer.from } ],attrs:{"outlined":"","dense":"","color":"secondary","hide-details":"","error-messages":_vm.errors.collect('tattooer-form.from'),"readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,false,616888549),model:{value:(_vm.menu.from),callback:function ($$v) {_vm.$set(_vm.menu, "from", $$v)},expression:"menu.from"}},[_c('v-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"first-day-of-week":"1","color":"primary","data-vv-validate-on":"blur","data-vv-name":"from","no-title":"","scrollable":"","show-current":_vm.today,"min":_vm.today},on:{"input":function($event){_vm.menu.from = false}},model:{value:(_vm.tattooer.from),callback:function ($$v) {_vm.$set(_vm.tattooer, "from", $$v)},expression:"tattooer.from"}})],1)],1)],1),(!_vm.invited)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('v-checkbox',{staticClass:"mt-7",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"margin-top":"2px"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.invites.indef"))+" ")])]},proxy:true}],null,false,3665403201),model:{value:(_vm.tattooer.indefinido),callback:function ($$v) {_vm.$set(_vm.tattooer, "indefinido", $$v)},expression:"tattooer.indefinido"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"5"}},[_c('label',{domProps:{"textContent":_vm._s('Hasta')}}),_c('v-menu',{ref:"menu",attrs:{"tabindex":"13","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-text-field',_vm._g({class:[
                        { 'error--text': _vm.tattooer.to < _vm.tattooer.from } ],attrs:{"outlined":"","dense":"","disabled":_vm.tattooer.indefinido,"color":"secondary","hide-details":"","error-messages":_vm.errors.collect('tattooer-form.to'),"readonly":""},model:{value:(_vm.computedToFormatted),callback:function ($$v) {_vm.computedToFormatted=$$v},expression:"computedToFormatted"}},on))]}}],null,false,992694568),model:{value:(_vm.menu.to),callback:function ($$v) {_vm.$set(_vm.menu, "to", $$v)},expression:"menu.to"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","data-vv-name":"to","no-title":"","scrollable":"","show-current":_vm.today,"min":_vm.today},on:{"input":function($event){_vm.menu.to = false}},model:{value:(_vm.tattooer.to),callback:function ($$v) {_vm.$set(_vm.tattooer, "to", $$v)},expression:"tattooer.to"}})],1)],1)],1):_vm._e(),(!_vm.invited)?_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"1"}}):_vm._e(),(
                  _vm.$store.getters['auth/getSetting'](
                    'calendar_relations_configurated'
                  )
                )?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('label',{staticClass:"one-line pl-0 ml-md-0 ml-n3 pl-md-4"},[_vm._v(" "+_vm._s(_vm.$t("calendar_configuration_tattooer_invite"))+" ")]),_c('v-autocomplete',{attrs:{"items":_vm.calendars,"outlined":"","dense":"","hide-details":"","single-line":"","clearable":"","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [(item.id === -1)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-close-circle ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [(item.id === -1)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-close-circle ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1955581564),model:{value:(_vm.tattooer.calendar_id),callback:function ($$v) {_vm.$set(_vm.tattooer, "calendar_id", $$v)},expression:"tattooer.calendar_id"}})],1):_vm._e(),_c('v-spacer')],1):_vm._e(),(
                !_vm.invited &&
                _vm.$store.getters['auth/getSetting']('comisions_active') === 1
              )?_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","max-width":"1000px"}},[_c('v-card-title',[_c('h2',{staticStyle:{"color":"var(--v-primary-base)","margin":"0 auto","text-transform":"uppercase","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.studio_tattooer_commissions"))+" ")])]),_c('v-card-text',{attrs:{"id":"select_commisions"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ComisionForm',{ref:"comisionForm",attrs:{"type":_vm.type,"commissions":_vm.tattooer.comisions}})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"pa-3 text-right"},[_c('v-btn',{staticClass:"mr-3",staticStyle:{"height":"25px","width":"100px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),(_vm.invited)?_c('v-btn',{staticStyle:{"width":"165px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0"},on:{"click":function($event){_vm.invited = null}}},[_c('v-icon',[_vm._v("mdi-")]),_vm._v(_vm._s(_vm.$t("tattooers.invite_another"))+" ")],1):_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"type":"submit","elevation":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$send")]),_vm._v(" "+_vm._s(_vm.$t("send", { name: "" }))+" ")],1)],1)],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }